import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
    createCreditNote,
    createCreditNoteAsync,
    getCreditNoteByIdAsync,
    getCreditNoteDocumentAsync,
    getCreditNoteDocumentsListAsync,
    getCreditNoteListAsync,
    getInvoiceByCustomerAsync
} from "./creditNote.async";

const initialState = {
    isLoading: false,
    isSubmitting: false,
    creditNoteDocumentDropDownLoader: false,
    creditNoteList : [],
    invoiceByCustomer : [],
    creditNoteDetails : {},
    creditNoteDocument : {},
    documentList : [],
    totalcreditNote: 0,
};

export const creditNoteSlice = createSlice({
  name: "invoice",
  initialState,
  extraReducers: (builder) => {
    // Create Invoice
    builder.addMatcher(isAnyOf(createCreditNote.pending), (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(
      isAnyOf(createCreditNote.fulfilled),
      (state, action) => {
        state.isLoading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(createCreditNote.rejected),
      (state) => {
        state.isLoading = false;
      }
    );
    
      // Get Receipt List
      builder.addMatcher(isAnyOf(getCreditNoteListAsync.pending), (state) => {
        state.isLoading = true;
      });
      builder.addMatcher(
        isAnyOf(getCreditNoteListAsync.fulfilled),
        (state, action) => {
          state.isLoading = false;
          state.creditNoteList = action.payload?.data?.data;
          state.totalcreditNote = action.payload?.data?.totalItems;
        }
      );
      builder.addMatcher(
        isAnyOf(getCreditNoteListAsync.rejected),
        (state, action) => {
          state.isLoading = false;
          state.creditNoteList = [];
        }
      );
    
      // Get Invoice By Customer
      builder.addMatcher(isAnyOf(getInvoiceByCustomerAsync.pending), (state) => {
        state.isLoading = true;
      });
      builder.addMatcher(
        isAnyOf(getInvoiceByCustomerAsync.fulfilled),
        (state, action) => {
          state.isLoading = false;
          state.invoiceByCustomer = action.payload?.data;
        }
      );
      builder.addMatcher(
        isAnyOf(getInvoiceByCustomerAsync.rejected),
        (state, action) => {
          state.isLoading = false;
          state.invoiceByCustomer = [];
        }
      );
    
      // Create Credit Note
      builder.addMatcher(isAnyOf(createCreditNoteAsync.pending), (state) => {
        state.isSubmitting = true;
      });
      builder.addMatcher(
        isAnyOf(createCreditNoteAsync.fulfilled),
        (state, action) => {
          state.isSubmitting = false;
        }
      );
      builder.addMatcher(
        isAnyOf(createCreditNoteAsync.rejected),
        (state, action) => {
          state.isSubmitting = false;
        }
      );
    
      // Get Credit Note by id
      builder.addMatcher(isAnyOf(getCreditNoteByIdAsync.pending), (state) => {
        state.isLoading = true;
      });
      builder.addMatcher(
        isAnyOf(getCreditNoteByIdAsync.fulfilled),
        (state, action) => {
          state.isLoading = false;
          state.creditNoteDetails = action?.payload?.data
        }
      );
      builder.addMatcher(
        isAnyOf(getCreditNoteByIdAsync.rejected),
        (state, action) => {
          state.isLoading = false;
          state.creditNoteDetails = {};
        }
      );
    
      // Get Credit Note Document List
      builder.addMatcher(isAnyOf(getCreditNoteDocumentsListAsync.pending), (state) => {
        state.creditNoteDocumentDropDownLoader = true;
      });
      builder.addMatcher(
        isAnyOf(getCreditNoteDocumentsListAsync.fulfilled),
        (state, action) => {
          state.creditNoteDocumentDropDownLoader = false;
          state.documentList = action?.payload?.data
        }
      );
      builder.addMatcher(
        isAnyOf(getCreditNoteDocumentsListAsync.rejected),
        (state, action) => {
          state.creditNoteDocumentDropDownLoader = false;
          state.documentList = [];
        }
      );
    
      // Get Credit Note Document
      builder.addMatcher(isAnyOf(getCreditNoteDocumentAsync.pending), (state) => {
        state.creditNoteDocumentDropDownLoader = true;
      });
      builder.addMatcher(
        isAnyOf(getCreditNoteDocumentAsync.fulfilled),
        (state, action) => {
          state.creditNoteDocumentDropDownLoader = false;
          state.creditNoteDocument = action?.payload?.data
        }
      );
      builder.addMatcher(
        isAnyOf(getCreditNoteDocumentAsync.rejected),
        (state, action) => {
          state.creditNoteDocumentDropDownLoader = false;
          state.creditNoteDocument = {};
        }
      );

      // ==========================
    }
});
export const { emptyInvoice } = creditNoteSlice.actions;
export default creditNoteSlice.reducer;
