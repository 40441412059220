import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

// getCreditNoteListAsync
export const getDebitNoteListAsync = createAsyncThunk(
  "debit-note/list-debit-note",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-all-debitnote?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&client=${
        payload?.customer || ""
      }&site=${payload?.site || ""}`,
      [],
      toolkit
    );
  }
);

// createDebitNoteAsync
export const createDebitNoteAsync = createAsyncThunk(
  "debit-note/create-debit-note",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/invoice/debit-note/create`,
      payload,
      toolkit
    );
  }
);

// getDebitNoteDocumentAsync
export const getDebitNoteDocumentAsync = createAsyncThunk(
  "debit-note/get-debit-note-document",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-debit-note-document-html/${payload?.documentId}/${payload?.debitNoteId}`,
      [],
      toolkit
    );
  }
);

// getDebitNoteByIdAsync
export const getDebitNoteByIdAsync = createAsyncThunk(
  "debit-note/get-debit-note-by-id",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/debit-note/${payload?.id}`,
      [],
      toolkit
    );
  }
);
