import { Loader } from 'components/Loader/index';
import { lazy } from 'react';

// DASHBOARD
export const Dashboard = Loader(
  lazy(() => import('../pages/DashboardPage/Dashboard'))
);
// Country
export const Country = Loader(
  lazy(() => import('../pages/Master/Country/CountryList'))
);
export const CountryCreate = Loader(
  lazy(() => import('../pages/Master/Country/CreateCountry'))
);

// state
export const State = Loader(
  lazy(() => import('../pages/Master/State/StateList'))
);
export const CreateState = Loader(
  lazy(() => import('../pages/Master/State/CreateState'))
);
// City
export const City = Loader(lazy(() => import('../pages/Master/City/CityList')));
export const CreateCity = Loader(
  lazy(() => import('../pages/Master/City/CreateCity'))
);

// Line of business
export const Business = Loader(
  lazy(() => import('../pages/Master/Business/LobList'))
);
export const BusinessAdd = Loader(
  lazy(() => import('../pages/Master/Business/LobCreate'))
);

// unit of measurement
export const Measurement = Loader(
  lazy(() => import('../pages/Master/Measurement/UomList'))
);
export const CreateMeasurement = Loader(
  lazy(() => import('../pages/Master/Measurement/CreateUom'))
);

// venor
export const Vendor = Loader(
  lazy(() => import('../pages/Master/Vendor/VendorList'))
);
export const CreateVendor = Loader(
  lazy(() => import('../pages/Master/Vendor/CreateVendor'))
);

// Service
export const Service = Loader(
  lazy(() => import('../pages/Master/Service/ServiceList'))
);
export const ServiceAdd = Loader(
  lazy(() => import('../pages/Master/Service/CreateService'))
);

//	Sites
export const Sites = Loader(
  lazy(() => import('../pages/Master/Sites/SitesList'))
);
export const CreateSites = Loader(
  lazy(() => import('../pages/Master/Sites/CreateSite'))
);
// Vessels
export const Vessels = Loader(
  lazy(() => import('../pages/Master/Vessels/VesselsList'))
);
export const CreateVessels = Loader(
  lazy(() => import('../pages/Master/Vessels/CreateVessels'))
);
// Activities
export const Activities = Loader(
  lazy(() => import('../pages/Master/Activities/ActivitiesList'))
);
export const CreateActivities = Loader(
  lazy(() => import('../pages/Master/Activities/CreateActivities'))
);
// VendorSku
export const VendorSku = Loader(
  lazy(() => import('../pages/Master/VendorSku/VendorskuList'))
);
export const CreateVendorsku = Loader(
  lazy(() => import('../pages/Master/VendorSku/createvendorsku'))
);

// Inventory
export const Inventory = Loader(
  lazy(() => import('../pages/Master/Inventory/InventoryList'))
);
export const CreateInventory = Loader(
  lazy(() => import('../pages/Master/Inventory/CreateInventory'))
);
// Document
export const Document = Loader(
  lazy(() => import('../pages/Master/Document/DocumentList'))
);
export const CreateDocument = Loader(
  lazy(() => import('../pages/Master/Document/CreateDocument'))
);
// LeadSource
export const LeadSource = Loader(
  lazy(() => import('../pages/Master/LeadSource/SourceList'))
);

export const CreateSource = Loader(
  lazy(() => import('../pages/Master/LeadSource/CreateSource'))
);

export const Accreditation = Loader(
  lazy(() => import('../pages/Master/Accreditation/AccreditationList'))
);
export const CreateAccreditation = Loader(
  lazy(() => import('../pages/Master/Accreditation/CreateAccreditation'))
);
export const Credential = Loader(
  lazy(() => import('../pages/Master/GstCredential/CredentialList'))
);
export const CreateCredential = Loader(
  lazy(() => import('../pages/Master/GstCredential/CreateCredential'))
);

// pincode
export const Pincode = Loader(
  lazy(() => import('../pages/Master/Pincode/Pincode'))
);
export const PincodeCreate = Loader(
  lazy(() => import('../pages/Master/Pincode/PincodeCreate'))
);

// Currency
export const Currency = Loader(
  lazy(() => import('../pages/Master/Currency/CurrencyList'))
);
export const CurrencyAdd = Loader(
  lazy(() => import('../pages/Master/Currency/CreateCurrency'))
);

// Pest
export const Pest = Loader(lazy(() => import('../pages/Master/Pest/PestList')));
export const CreatePest = Loader(
  lazy(() => import('../pages/Master/Pest/CreatePest'))
);

// CHANGE PASSWORD
export const ChangePassword = Loader(
  lazy(() => import('../pages/auth/ChangePassword'))
);
// GENERAL SETTINGS
export const SiteSettings = Loader(
  lazy(() => import('../pages/GeneralSetting/SiteSettings/SiteSettings'))
);
// 404
export const Page404 = Loader(lazy(() => import('../pages/Page404')));
// NEW PASSWORD
export const NewPassword = Loader(
  lazy(() => import('../pages/auth/NewPassword'))
);
export const Login = Loader(lazy(() => import('../pages/auth/Login')));
// RESET PASSWORD
export const ResetPassword = Loader(
  lazy(() => import('../pages/auth/ResetPassword'))
);
export const IndivisualSetting = Loader(
  lazy(() =>
    import('../pages/GeneralSetting/IndivisualSetting/IndivisualSetting')
  )
);
// VERFY CODE
export const VerifyCode = Loader(
  lazy(() => import('../pages/auth/VerifyCode'))
);

//Roles
export const Roles = Loader(
  lazy(() => import('../pages/UserManagement/Roles/Roles'))
);
export const Permission = Loader(
  lazy(() => import('../pages/UserManagement/Roles/Permission/Permission'))
);

// 	Purchase
export const Purchase = Loader(
  lazy(() => import('../pages/PurchaseOrder/PurchaseOrderList'))
);
export const CreatePurchase = Loader(
  lazy(() => import('../pages/PurchaseOrder/CreatePurchaseOrder'))
);

// Sales

export const Sales = Loader(
  lazy(() => import('../pages/SalesOrder/Sales/salesList'))
);
export const CreateSales = Loader(
  lazy(() => import('../pages/SalesOrder/Sales/CreateSales'))
);

//User
export const User = Loader(
  lazy(() => import('../pages/UserManagement/User/User'))
);
export const CreateUser = Loader(
  lazy(() => import('../pages/UserManagement/User/CreateUser/CreateUser'))
);

//Lead
export const Lead = Loader(lazy(() => import('../pages/Lead/Lead')));
export const LeadCreate = Loader(
  lazy(() => import('../pages/Lead/CreateLead'))
);
export const Customers = Loader(
  lazy(() => import('../pages/Customers/CustomersList'))
);

//Quotation
export const Quotations = Loader(
  lazy(() => import('../pages/Quotations/Quotations'))
);
export const QuotationsCreate = Loader(
  lazy(() => import('../pages/Quotations/CreateQuotation'))
);

// Lead View
export const LeadView = Loader(lazy(() => import('../pages/LeadView/View')));

// Contract
export const Contract = Loader(
  lazy(() => import('../pages/Contract/ContractList'))
);
export const CreateContract = Loader(
  lazy(() => import('../pages/Contract/CreateContract'))
);

// Inbound
export const Inbound = Loader(
  lazy(() => import('../pages/Inbound/InboundList'))
);
export const CreateInbound = Loader(
  lazy(() => import('../pages/Inbound/CreateInbound'))
);

// Roster
export const Roster = Loader(lazy(() => import('../pages/Roster/Roster')));

// InventoryNoMenu
export const InventoryNoMenuList = Loader(
  lazy(() => import('../pages/InventoryNoMenuList/InventoryNoMenuList'))
);

// Invoice

export const Invoice = Loader(
  lazy(() => import('../pages/Invoice/Invoice/InvoiceList'))
);
export const CreditNote = Loader(
  lazy(() => import('../pages/Invoice/CreditNote/pages/CreditNoteLists'))
);
export const CreateCreditNote = Loader(
  lazy(() => import('../pages/Invoice/CreditNote/pages/CreateCreditNote'))
);
export const DebitNote = Loader(
  lazy(() => import('../pages/Invoice/DebitNote/pages/DebitNoteList'))
);
export const CreateDebitNote = Loader(
  lazy(() => import('../pages/Invoice/DebitNote/pages/CreateDebitNote'))
);
export const CreateInvoice = Loader(
  lazy(() => import('../pages/Invoice/Invoice/CreateInvoice'))
);

export const GetAllReceipt = Loader(
  lazy(() => import('../pages/Invoice/Invoice/ReceiptDetails'))
);

export const UpdateReciept = Loader(
  lazy(() => import('../pages/Invoice/Invoice/UpdateReciept'))
);

// Receipt
export const Receipt = Loader(
  lazy(() => import('../pages/Invoice/Receipt/ReceiptList'))
);

export const CreateReceipt = Loader(
  lazy(() => import('../pages/Invoice/Receipt/CreateReceipt'))
);

//Services
export const MainServices = Loader(
  lazy(() => import('../pages/Services/Service/ServiceList'))
);
export const JobsSchedule = Loader(
  lazy(() => import('../pages/Services/Service/JobsSchedule'))
);

// SendSlip
export const SendSlip = Loader(
  lazy(() => import('../pages/Services/Service/SendSlip/SendSlip'))
);

//Jobs
export const Jobs = Loader(
  lazy(() => import('../pages/Services/Jobs/JobList'))
);

export const CreateJob = Loader(
  lazy(() => import('../pages/Services/Jobs/CreateJob'))
);

export const Certificates = Loader(
  lazy(() => import('../pages/Services/Certificates/CertificateList'))
);
export const CreateCertificate = Loader(
  lazy(() => import('../pages/Services/Certificates/CreateCertificate'))
);
export const CustomerEdit = Loader(
  lazy(() => import('../pages/Services/Certificates/CustomerEdit'))
);

//operations
export const InventoryAllocations = Loader(
  lazy(() => import('../pages/Operations/InventoryAllocationsList'))
);
export const CreateInventoryAllocations = Loader(
  lazy(() => import('../pages/Operations/CreateInventoryAllocations'))
);

// QuotaionsView
export const QuotaionsView = Loader(
  lazy(() => import('../pages/QuotationsView/QuotationsViewList'))
);

// Feedback
export const Feedback = Loader(
  lazy(() => import('../pages/Feedback/FeedbackList'))
);

export const Expense = Loader(
  lazy(() => import('../pages/Expenses/ExpenseList'))
);

// Port
export const PortList = Loader(
  lazy(() => import('../pages/Master/Port/PortList'))
);
export const CreatePort = Loader(
  lazy(() => import('../pages/Master/Port/CreatePort'))
);
// Roles and permission
export const AssignPermission = Loader(
  lazy(() =>
    import('../pages/UserManagement/Roles/CreateRoles/AssignPermission')
  )
);

// Attendance
export const AttendanceList = Loader(
  lazy(() => import('../pages/Attendance/AttendanceList'))
);

// View Activities
export const ViewActivitiesList = Loader(
  lazy(() => import('../pages/Services/Jobs/ViewActivities/ViewActivities'))
);

// Goverment Document

export const AppendixList = Loader(
  lazy(() => import('../pages/GovermentDocument/Appendix/AppendixList'))
);

// Mis
export const LeadConversion = Loader(
  lazy(() => import('../pages/Mis/LeadConversion/LeadConversion'))
);
export const QuotationConversion = Loader(
  lazy(() => import('../pages/Mis/QuotationConversion/QuotationConversion'))
);
export const ContractConversion = Loader(
  lazy(() => import('../pages/Mis/ContractConversion/ContractConversion'))
);
export const RevenueNumbers = Loader(
  lazy(() => import('../pages/Mis/RevenueNumbers/RevenueNumbers'))
);
export const Profitability = Loader(
  lazy(() => import('../pages/Mis/Profitability/Profitablity'))
);
export const CollectionPercentage = Loader(
  lazy(() => import('../pages/Mis/CollectionPercentage/CollectionPercentage'))
);
export const TotalCustomerCount = Loader(
  lazy(() => import('../pages/Mis/TotalCustomerCount/TotalCustomerCount'))
);
export const ManPowerUtilization = Loader(
  lazy(() => import('../pages/Mis/ManPowerUtilization/ManPowerUtilization'))
);
export const CreateDuplicate = Loader(
  lazy(() => import('../pages/Quotations/CreateDuplicate'))
);
export const CustomersRevenue = Loader(
  lazy(() => import('../pages/Mis/CustomersRevenue/CustomersRevenue'))
);
export const CustomersPayables = Loader(
  lazy(() => import('../pages/Mis/CustomersPayables/CustomersPayables'))
);
export const CustomersDelaying = Loader(
  lazy(() => import('../pages/Mis/CustomersDelaying/CustomersDelaying'))
);
export const GstReport = Loader(
  lazy(() => import('../pages/Mis/gstReport/GstReport'))
);
export const OutstandingReport = Loader(
  lazy(() => import('../pages/Mis/OutstandingReport/OutstandingReport'))
);
export const ContainerReport = Loader(
  lazy(() => import('../pages/Mis/ContainerReport/ContainerReport'))
);
export const RenewalSystemQuotations = Loader(
  lazy(() => import('../pages/RenewalSystem/RenewalSystemQuotations'))
);
