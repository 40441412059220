import { configureStore } from "@reduxjs/toolkit";
// IMPORTED ADMIN LOGIN SLICE
import loginSlice from "./slices/login.slice";
import resetPasswordSlice from "./slices/ResetPassword.slice.js";
import errorSlice from "./slices/error.slice";
import individualSettingSlice from "../redux/slices/individualSetting/individualSetting.slice";
import userInfoSlice from "./slices/userinfo.slice";
import adminSlice from "./slices/SiteSettingsSlice/Admin.slice";
import permissionSlice from "./slices/permission/permission.slice";
import menuPermissionSlice from "./menusPermission/menupermission";
import dashboardSlice from "./dashboard/dashboard.slice";
import lobSlice from "./slices/lob/lob.slice";
import serviceSlice from "./slices/service/service.slice";
import stateSlice from "./slices/state/state.slice";
import countrySlice from "./slices/country/country.slice";
import uomSlice from "./slices/uom/uom.slice";
import vendorSlice from "./slices/vendor/vendor.slice";
import citySlice from "./slices/city/city.slice";
import leadSourceSlice from "./slices/leadsource/leadsource.slice";
import pincodeSlice from "./slices/pincode/pincode.slice";
import rolesSlice from "./Roles/roles.slice";
import sitesSlice from "./slices/sites/sites.slice";
import accreditationSlice from "./slices/accreditation/accreditation.slice";
import skuSlice from "./slices/vendorsku/sku.slice";
import vesselSlice from "./slices/vessel/vessel.slice";
import inventorySlice from "./slices/inventory/inventory.slice";
import currencySlice from "./slices/currency/currency.slice";
import userSlice from "./user/user.slice";
import customerSlice from "./slices/customer/customer.slice";
import activitiesSlice from "./slices/activities/activities.slice";
import salesSlice from "./slices/sales/sales.slice";
import leadSlice from "./slices/lead/lead.slice";
import quotationSlice from "./slices/quotation/quotation.slice";
import UploadSlice from "../redux/upload/upload.slice";
import contractSlice from "./slices/contract/contract.slice";
import followUpSlice from "./slices/followup/followup.slice";
import notesSlice from "./slices/notes/notes.slice";
import jobsSlice from "./slices/jobs/jobs.slice";
import purchaseOrderSlice from "./slices/purchaseOrder/purchaseOrder.slice";

import rosterSlice from "./slices/roster/roster.slice";
import servicesSlice from "./slices/services/services.slice";
import documentSlice from "./slices/document/document.slice";
import invoiceSlice from "./slices/invoice/invoice.slice";
import portSlice from "./slices/port/port.slice";
import inboundSlice from "./slices/inbound/inbound.slice";
import attendanceSlice from "./slices/attendance/attendance.slice";
import expenseSlice from "./slices/expense/expense.slice";
import allocationSlice from "./slices/inventoryAllocation/allocation.slice";
import menuSlice from "./slices/routes/menus.slices";

import certificateSlice from "./slices/certificate/certificate.slice";
import pestSlice from "./slices/pest/pest.slice";
import leadConversionSlice from "./slices/mis/leadConversion/leadConversion.slice";
import contractConversionSlice from "./slices/mis/contractConversion/contractConversion.slice";
import quotationConversionSlice from "./slices/mis/quotationConversion/quotationConversion.slice";
import revenueNumberSlice from "./slices/mis/revenueNumbers/revenueNumber.slice";
import manPowerUtilizationSlice from "./slices/mis/revenueNumbers/revenueNumber.slice";
import collectionPercentageSlice from "./slices/mis/collectionPercentage/collectionPercentage.slice";
import gstSlice from "./slices/gst/gst.slice";
import otpSlice from "./slices/otpslice/otp.slice";
import totalCustomerCountSlice from "./slices/mis/totalCustomerCountLineChart/totalCustomerCount.slice";
import profitabilityChartSlice from "./slices/mis/profitability/profitability.slice";
import noMenuInventorySlice from "./slices/InventoryNoMenu/InventoryNoMenu.slice";
import credentialSlice from "./slices/gstcredential/credential.slice";
import receiptSlice from "./slices/receipt/receipt.slice";
import creditNoteSlice from "./slices/creditNote/creditNote.slice";
import debitNoteSlice from "./slices/debitNote/debitNote.slice";




export const store = configureStore({
  reducer: {
    menuPermission: menuPermissionSlice,
    error: errorSlice,
    individualSetting: individualSettingSlice,
    login: loginSlice,
    ResetPassword: resetPasswordSlice,
    userinfo: userInfoSlice,
    admin: adminSlice,
    permission: permissionSlice,
    dashboard: dashboardSlice,
    lob: lobSlice,
    country: countrySlice,
    city: citySlice,
    uom: uomSlice,
    vendor: vendorSlice,
    vendorsku: skuSlice,
    user: userSlice,

    menu: menuSlice,
    leadsource: leadSourceSlice,
    service: serviceSlice,
    roles: rolesSlice,
    activities: activitiesSlice,
    vessel: vesselSlice,
    state: stateSlice,
    sites: sitesSlice,
    vessel: vesselSlice,
    accreditation: accreditationSlice,
    inventory: inventorySlice,
    pincode: pincodeSlice,
    sales: salesSlice,
    currency: currencySlice,
    pest: pestSlice,
    lead: leadSlice,
    customer: customerSlice,
    upload: UploadSlice,
    quotation: quotationSlice,
    contract: contractSlice,
    followUp: followUpSlice,
    notes: notesSlice,
    jobs: jobsSlice,
    purchaseOrder: purchaseOrderSlice,

    roster: rosterSlice,
    document: documentSlice,
    invoice: invoiceSlice,
    receipt: receiptSlice,
    creditNote: creditNoteSlice,
    debitNote: debitNoteSlice,
    port: portSlice,
    inbound: inboundSlice,
    attendance: attendanceSlice,
    expense: expenseSlice,
    allocation: allocationSlice,
    certificate: certificateSlice,
    services: servicesSlice,
    leadConversion: leadConversionSlice,
    contractConversion: contractConversionSlice,
    quotationConversion: quotationConversionSlice,
    revenueNumbers: revenueNumberSlice,
    collectionPercentage: collectionPercentageSlice,
    gst:gstSlice,
    otp: otpSlice,
    totalCustomerCount: totalCustomerCountSlice,
    profitabilityChart: profitabilityChartSlice,
    noMenuInventory: noMenuInventorySlice,
    credential: credentialSlice,
    manPowerUtilization: manPowerUtilizationSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
