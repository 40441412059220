import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createDebitNoteAsync,
  getDebitNoteByIdAsync,
  getDebitNoteListAsync,
  getDebitNoteDocumentAsync,
} from "./debitNote.async";

const initialState = {
  isLoading: false,
  isSubmitting: false,
  debitNoteList: [],
  invoiceByCustomer: [],
  debitNoteDetails: {},
  totalDebitNote: 0,
  debitNoteDocumentDropDownLoader: false,
  debitNoteDocument : {},
};

export const debitNoteSlice = createSlice({
  name: "debit-note",
  initialState,
  extraReducers: (builder) => {
    // Get Receipt List
    builder.addMatcher(isAnyOf(getDebitNoteListAsync.pending), (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(
      isAnyOf(getDebitNoteListAsync.fulfilled),
      (state, action) => {
        state.isLoading = false;
        state.debitNoteList = action.payload?.data?.data;
        state.totalDebitNote = action.payload?.data?.totalItems;
      }
    );
    builder.addMatcher(
      isAnyOf(getDebitNoteListAsync.rejected),
      (state, action) => {
        state.isLoading = false;
        state.debitNoteList = [];
      }
    );

    // Create Credit Note
    builder.addMatcher(isAnyOf(createDebitNoteAsync.pending), (state) => {
      state.isSubmitting = true;
    });
    builder.addMatcher(
      isAnyOf(createDebitNoteAsync.fulfilled),
      (state, action) => {
        state.isSubmitting = false;
      }
    );
    builder.addMatcher(
      isAnyOf(createDebitNoteAsync.rejected),
      (state, action) => {
        state.isSubmitting = false;
      }
    );

    // Get Credit Note by id
    builder.addMatcher(isAnyOf(getDebitNoteByIdAsync.pending), (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(
      isAnyOf(getDebitNoteByIdAsync.fulfilled),
      (state, action) => {
        state.isLoading = false;
        state.debitNoteDetails = action?.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getDebitNoteByIdAsync.rejected),
      (state, action) => {
        state.isLoading = false;
        state.debitNoteDetails = {};
      }
    );

    // Get Credit Note by id
    builder.addMatcher(isAnyOf(getDebitNoteDocumentAsync.pending), (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(
      isAnyOf(getDebitNoteDocumentAsync.fulfilled),
      (state, action) => {
        state.debitNoteDocumentDropDownLoader = false;
        state.debitNoteDocument = action?.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getDebitNoteDocumentAsync.rejected),
      (state, action) => {
        state.debitNoteDocumentDropDownLoader = false;
        state.debitNoteDocument = {};
      }
    );
    // ==========================
  },
});
export const { emptyInvoice } = debitNoteSlice.actions;
export default debitNoteSlice.reducer;
