import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

//create Credit Note
export const createCreditNote = createAsyncThunk(
  "invoice/createCreditNote",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/invoice/add-remark/${payload?.id}`,
      payload,
      toolkit
    );
  }
);

// getCreditNoteListAsync
export const getCreditNoteListAsync = createAsyncThunk(
  "credit-note/list-receipt",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-all-creditnote?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&client=${
        payload?.customer || ""
      }&site=${payload?.site || ""}`,
      [],
      toolkit
    );
  }
);

export const deleteCreditNoteAsync = createAsyncThunk(
  "credit-note/deleteCreditNote",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/invoice/delete-creditnote/${payload}`,
      [],
      toolkit
    );
  }
);

export const deleteDebitNoteAsync = createAsyncThunk(
  "debit-note/deleteDebitNote",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/invoice/delete-debitnote/${payload}`,
      [],
      toolkit
    );
  }
);

// getInvoiceByCustomerAsync
export const getInvoiceByCustomerAsync = createAsyncThunk(
  "credit-note/list-invoice-by-customer",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/creditnote-invoice?client=${payload?.client}`,
      [],
      toolkit
    );
  }
);

// createCreditNoteAsync
export const createCreditNoteAsync = createAsyncThunk(
  "credit-note/create-credit-note",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/invoice/credit-note/create`,
      payload,
      toolkit
    );
  }
);

// getCreditNoteByIdAsync
export const getCreditNoteByIdAsync = createAsyncThunk(
  "credit-note/get-credit-note-by-id",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/credit-note/${payload?.id}`,
      [],
      toolkit
    );
  }
);

// getCreditNoteDocumentsListAsync
export const getCreditNoteDocumentsListAsync = createAsyncThunk(
  "credit-note/get-credit-note-document-list",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/list-document-dropdown/${payload.type}`,
      [],
      toolkit
    );
  }
);

// getCreditNoteDocumentAsync
export const getCreditNoteDocumentAsync = createAsyncThunk(
  "credit-note/get-credit-note-document",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/get-credit-note-document-html/${payload?.documentId}/${payload?.creditNoteId}`,
      [],
      toolkit
    );
  }
);
