// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Iconify from "components/iconify";
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  // blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  // chat: icon("ic_chat"),
  // mail: icon("ic_mail"),
  user: icon("ic_user"),
  // file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  // blank: icon("ic_blank"),
  // kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),

  // invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  // disabled: icon("ic_disabled"),
  // external: icon("ic_external"),
  // menuItem: icon("ic_menu_item"),
  // ecommerce: icon("ic_ecommerce"),
  // analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard")
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      // {
      //   title: "Dashboard",
      //   path: "/app/dashboard",
      //   icon: ICONS.dashboard,
      // },

      {
        title: "User Management",
        path: PATH_DASHBOARD.userManagement,
        icon: <Iconify icon="mdi:graph" width={28} />,
        children: [
          {
            title: "Role",
            path: "/app/user-management/roles",
            icon: ICONS.dashboard
          },
          {
            title: "Users",
            path: "/app/user-management/user-list",
            icon: ICONS.dashboard
          }
        ]
      },

      {
        title: "Masters",
        path: PATH_DASHBOARD.master,
        icon: <Iconify icon="mdi:table" width={28} />,
        children: [
          {
            title: "Country",
            path: "/app/master/country",
            icon: ICONS.dashboard
          },

          {
            title: "State",
            path: "/app/master/state",
            icon: ICONS.dashboard
          },
          {
            title: "Port",
            path: "/app/master/port",
            icon: ICONS.dashboard
          },
          {
            title: "City",
            path: "/app/master/city",
            icon: ICONS.dashboard
          },
          {
            title: "Line Of Business",
            path: "/app/master/business",
            icon: ICONS.dashboard
          },
          {
            title: "Unit Of Measurement",
            path: "/app/master/measurement",
            icon: ICONS.dashboard
          },

          {
            title: "Vendor Details",
            path: "/app/master/vendor",
            icon: ICONS.dashboard
          },
          {
            title: "Service Details",
            path: "/app/master/service",
            icon: ICONS.dashboard
          },
          {
            title: "Sites",
            path: "/app/master/sites",
            icon: ICONS.dashboard
          },
          {
            title: "Vessels",
            path: "/app/master/vessels",
            icon: ICONS.dashboard
          },

          {
            title: "Activities",
            path: "/app/master/activities",
            icon: ICONS.dashboard
          },

          {
            title: "Vendor Sku",
            path: "/app/master/vendorsku",
            icon: ICONS.dashboard
          },

          {
            title: "Inventory",
            path: "/app/master/inventory",
            icon: ICONS.dashboard
          },
          {
            title: "Document",
            path: "/app/master/Document",
            icon: ICONS.dashboard
          },
          {
            title: "Lead Source",
            path: "/app/master/leadsource",
            icon: ICONS.dashboard
          },
          {
            title: "Accreditation",
            path: "/app/master/accreditation",
            icon: ICONS.dashboard
          },
          {
            title: "Pincode",
            path: "/app/master/pincode",
            icon: ICONS.dashboard
          },
          {
            title: "Currency",
            path: "/app/master/currency",
            icon: ICONS.dashboard
          }
        ]
      },

      {
        title: "Invoice",
        path: PATH_DASHBOARD.invoice,
        icon: <Iconify icon="uil:invoice" width={28} />,
        children: [
          {
            title: "Invoice",
            path: "/app/invoice/invoices",
            icon: ICONS.dashboard
          },
          {
            title: "Credit Note",
            path: "/app/invoice/creditNote",
            icon: ICONS.dashboard
          },
          {
            title: "Debit Note",
            path: "/app/invoice/creditNote",
            icon: ICONS.dashboard
          },
        ]
      },
      {
        title: "Lead",
        path: PATH_DASHBOARD.lead,
        icon: <Iconify icon="mdi:user" width={28} />
      },

      {
        title: "Customers",
        path: PATH_DASHBOARD.customers,
        icon: <Iconify icon="mdi:users" width={28} />
      },

      {
        title: "Contract",
        path: PATH_DASHBOARD.contract,

        icon: <Iconify icon="bi:file-earmark-text" width={28} />
      },

      {
        title: "Attendance",
        path: PATH_DASHBOARD.attendance,

        icon: <Iconify icon="mdi:users" width={28} />
      },

      {
        title: "Inbound",
        path: PATH_DASHBOARD.inbound,
        icon: ICONS.dashboard,
        icon: <Iconify icon="gg:log-out" width={28} />
      },

      {
        title: "Sales Order",
        path: PATH_DASHBOARD.sales,
        icon: <Iconify icon="gg:shopping-bag" width={28} />
      },

      {
        title: "Purchase Order",
        path: PATH_DASHBOARD.purchase,
        icon: ICONS.dashboard,
        icon: <Iconify icon="ph:briefcase-metal" width={28} />
      },

      {
        title: "Quotations",
        path: PATH_DASHBOARD.quotation,
        icon: <Iconify icon="mdi:user" width={28} />
      },
      {
        title: "Roster",
        path: PATH_DASHBOARD.roster,
        icon: ICONS.dashboard,
        icon: <Iconify icon="gg:shutterstock" width={28} />
      },
      {
        title: "Feedback",
        path: PATH_DASHBOARD.feedback,
        icon: <Iconify icon="mdi:users" width={28} />
      },

      {
        title: "Expense",
        path: PATH_DASHBOARD.expense,
        icon: <Iconify icon="uil:invoice" width={28} />
      },

      {
        title: "Services",
        path: PATH_DASHBOARD.services,
        icon: <Iconify icon="mdi:customer-service" width={28} />,
        children: [
          {
            title: "Service",
            path: "/app/services/mainservice",
            icon: ICONS.dashboard
          },
          {
            title: "Jobs",
            path: "/app/services/jobs",
            icon: ICONS.dashboard
          },
          {
            title: "Certificates",
            path: "/app/services/certificates",
            icon: ICONS.dashboard
          }
        ]
      },
      {
        title: "Operations",
        path: PATH_DASHBOARD.operations,
        icon: <Iconify icon="mdi:customer-service" width={28} />,
        children: [
          {
            title: "Inventory Allocations",
            path: "/app/operations/inventoryallocations",
            icon: ICONS.dashboard
          }
        ]
      },

      {
        title: "Goverment Document",
        path: PATH_DASHBOARD.governmentdocument,
        icon: <Iconify icon="mdi:customer-service" width={28} />,
        children: [
          {
            title: "Appendix",
            path: "/app/govermentdocument/appendix",
            icon: ICONS.dashboard
          }
        ]
      }
    ]
  }
];

export default navConfig;
